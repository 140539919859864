$(document).scroll(function(){
    if($(this).scrollTop() + $(window).height() > $("#why ul").position().top){
        $("#why").addClass("active");
    }
    if($(this).scrollTop() > $("header").height()){
        $("#fixed-bar").addClass("active");
    }else{
        $("#fixed-bar").removeClass("active");
    }
});

$("#fixed-bar a").click(function(){
    $("html, body").animate({"scrollTop": 0});
    return false;
});

new Cleave("input[name='phoneNumber']", {
    phone: true,
    phoneRegionCode: 'us'
});

$("#form .successful a").click(function(){
    $("#form form").show();
    $("#form .successful").hide();
    return false;
});

$.validate({
    onSuccess: function(){
        $("#form form").hide();
        $("#form .successful").show();
        $.post("action.php", $("form").serialize(), function(response){
            $("#form form")[0].reset();
        });
        return false;
    }
});

$.fancybox.defaults.touch = false;
//$.fancybox.defaults.buttons = ["close"];